import React from "react";
import GridContainer from "../Grid/grid-container";
import Box from "../Box";
import Flex from "../Flex";
import Text from "../Text";
import styled from "styled-components";
import { mediaQuery, remCalc } from "../../../theme/utils";

const StyledFooterFlex = styled(Flex)`
  align-items: center;

  & > :nth-child(1) {
    order: 2;
    width: 100%;

    ${mediaQuery.tablet`
      width: max-content;
      order: 1;
      `}
  }
  & > :nth-child(2) {
    order: 1;
    width: 100%;

    ${mediaQuery.tablet`
      width: max-content;
      order: 2;
    `}
  }
`;

const StyledSocialLinks = styled.ul`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.md};

  ${mediaQuery.tablet`
    margin-bottom: 0;
  `}

  & > * + * {
    margin-left: ${props => props.theme.spacing.md};
  }

  a {
    color: ${props => props.theme.colors.gray500};
    letter-spacing: ${remCalc(1.25)};
    text-decoration: none;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: ${props => props.theme.colors.gray900};
    }
  }
`;

const Footer = () => {
  return (
    <Box
      as="footer"
      background="white"
      paddingTop="lg"
      paddingBottom="lg"
      zIndex={2}
    >
      <GridContainer>
        <StyledFooterFlex wrap align="center" justify="space-between">
          <Text color="gray500">©2020 Samakintunde. All rights reserved.</Text>

          <StyledSocialLinks>
            <li>
              <a href="https://twitter.com/samakintunde37">Tw</a>
            </li>
            <li>
              <a href="https://github.com/samakintunde37">Gh</a>
            </li>
            <li>
              <a href="https://linkedin.com/in/samuel-akintunde">Li</a>
            </li>
          </StyledSocialLinks>
        </StyledFooterFlex>
      </GridContainer>
    </Box>
  );
};

export default Footer;
