import React from "react";
import PropTypes from "prop-types";
// import { useStaticQuery, graphql } from "gatsby";
import { ThemeProvider } from "styled-components";
import styled from "styled-components";

import "../../../theme/layout.css";
import { GlobalStyle } from "../../../theme/global-styles";

import theme from "../../../theme";

import Header from "../Header";
import Footer from "../Footer";

// const data = useStaticQuery(graphql`
//   query SiteTitleQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `);

const VisuallyHidden = styled.div`
  opacity: 0;
  visibility: hidden;
  height: 0;
`;

export const MinViewPortHeightContainer = styled.div`
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <VisuallyHidden>
      <a href="#main">Skip to content</a>
    </VisuallyHidden>
    <MinViewPortHeightContainer>
      <Header />
      {children}
      <Footer />
    </MinViewPortHeightContainer>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
