const TYPOGRAPHY = {
  h1: "var(--h1-size)",
  h2: "var(--h2-size)",
  h3: "var(--h3-size)",
  h4: "var(--h4-size)",
  h5: "var(--h5-size)",
  h6: "var(--h6-size)",
  large: "var(--body-large-size)",
  regular: "var(--body-regular-size)",
  small: "var(--body-small-size)",
};

export default TYPOGRAPHY;
