import spacing from "./spacing";
import breakpoint from "./breakpoint";
import colors from "./color";
import typography from "./typography";
import container from "./layout";

const theme = {
  spacing,
  breakpoint,
  colors,
  typography,
  ...container,
};

export default theme;
