const COLORS = {
  brand100: "var(--brand-100)",
  brand200: "var(--brand-200)",
  brand300: "var(--brand-300)",
  brand400: "var(--brand-400)",
  brand500: "var(--brand-500)",
  brand600: "var(--brand-600)",
  brand700: "var(--brand-700)",
  brand800: "var(--brand-800)",
  brand900: "var(--brand-900)",
  gray100: "var(--gray-100)",
  gray200: "var(--gray-200)",
  gray300: "var(--gray-300)",
  gray400: "var(--gray-400)",
  gray500: "var(--gray-500)",
  gray600: "var(--gray-600)",
  gray700: "var(--gray-700)",
  gray800: "var(--gray-800)",
  gray900: "var(--gray-900)",
  black: "var(--black)",
  white: "var(--white)",
};

export default COLORS;
