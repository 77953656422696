import styled from "styled-components";

const GridContainer = styled.div`
  max-width: ${props => props.theme.maxWidth};
  padding-left: ${props => props.theme.gutter};
  padding-right: ${props => props.theme.gutter};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: ${props => props.height};
`;

export default GridContainer;
