import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import GridContainer from "../Grid/grid-container";
import Flex from "../Flex";
import Text from "../Text";
import Anchor from "../Anchor";
import { mediaQuery, remCalc } from "../../../theme/utils";
import Box from "../Box";

const StyledHeader = styled.header`
  padding-top: ${props => props.theme.spacing.lg};
  padding-bottom: ${props => props.theme.spacing.lg};

  p {
    font-family: var(--title-font);
    letter-spacing: ${remCalc(0.5)};
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const StyledNav = styled.nav`
  display: none;

  ${mediaQuery.desktop`
    display: block;
  `}

  ul {
    display: flex;
    margin-bottom: 0;
    list-style: none;

    &:hover a:not(:hover) {
      opacity: 0.5;
    }

    li {
      margin-bottom: 0;
      text-transform: uppercase;
      text-align: center;

      ${mediaQuery.desktop`
        text-align: left;
      `}
    }

    li a {
      color: ${props => props.theme.colors.gray700};
      font-size: ${remCalc(24)};
      text-decoration: none;
      transition: opacity 0.3s ease-in-out;

      ${mediaQuery.desktop`
        font-size: ${remCalc(14)};
      `}
    }

    > * + * {
      ${mediaQuery.desktop`
        margin-left: ${props => props.theme.spacing.lg};
      `}
    }
  }
`;

const StyledMobileNav = styled.nav`
  position: fixed;
  top: 0;
  left: 100vw;
  width: 100%;
  height: 100vh;
  background-color: ${props => props.theme.colors.gray100};
  padding: ${props => props.theme.spacing.md};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
  will-change: transform;

  ${props =>
    props.open &&
    css`
      transform: translateX(-100%);
    `}

  ul {
    display: flex;
    flex-direction: column;

    &:hover a:not(:hover) {
      opacity: 0.5;
    }

    > * + * {
      margin-top: ${props => props.theme.spacing.lg};
    }
  }

  li {
    /* text-transform: uppercase; */
    text-align: center;
  }

  li a {
    color: ${props => props.theme.colors.gray700};
    font-size: ${remCalc(36)};
    letter-spacing: ${remCalc(1.25)};
    text-decoration: none;
    transition: opacity 0.3s ease-in-out;
  }

  ${mediaQuery.desktop`
    display: none;
  `}

  .close-btn {
    display: flex;
    justify-content: center;
    text-align: center;
    border: ${props => `${remCalc(1)} solid ${props.theme.colors.gray900}`};
    border-radius: ${remCalc(40)};
    cursor: pointer;
  }
`;

const hamburger = {
  height: 8,
  width: 24,
  paddingX: 8,
  paddingY: 16,
};

const StyledHamburger = styled.div`
  cursor: pointer;
  height: ${remCalc(
    hamburger.paddingY + hamburger.height + hamburger.paddingY
  )};
  width: ${remCalc(hamburger.paddingX + hamburger.width + hamburger.paddingX)};
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: ${remCalc(hamburger.width)};
    height: ${remCalc(1)};
    background-color: ${props => props.theme.colors.brand500};
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s ease-in-out;
    margin-left: ${remCalc(-12)};
  }

  ${props =>
    props.open
      ? css`
          &::after {
            transform: translateY(0) rotate(45deg);
          }
          &::before {
            transform: translateY(0) rotate(-45deg);
          }
        `
      : css`
          &::before {
            transform: translateY(${remCalc(-hamburger.height / 2)});
          }

          &::after {
            transform: translateY(${remCalc(hamburger.height / 2)});
          }
        `}

  ${mediaQuery.desktop`
    display: none;
  `}
`;

const Header = ({ siteTitle }) => {
  const [navOpen, setNavOpen] = useState(false);

  const handleNav = () => {
    setNavOpen(!navOpen);
  };

  const handleEsc = e => {
    if (e.key === "Escape") {
      setNavOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  useEffect(() => {
    if (navOpen) {
      console.log(window.scrollY);
      document.body.style = `
        overflow: hidden;
      `;
    } else {
      document.body.style = null;
    }
  }, [navOpen]);

  return (
    <StyledHeader>
      <GridContainer>
        <Flex align="center" justify="space-between">
          <Text>Samakintunde</Text>
          <StyledNav>
            <ul>
              <li>
                <Anchor to="/">Home</Anchor>
              </li>
              <li>
                <Anchor to="/about">About</Anchor>
              </li>
              <li>
                <Anchor to="/blog">Blog</Anchor>
              </li>
              <li>
                <Anchor to="/lists">Lists</Anchor>
              </li>
            </ul>
          </StyledNav>
          <StyledMobileNav open={navOpen}>
            <ul>
              <li>
                <Anchor to="/">Home</Anchor>
              </li>
              <li>
                <Anchor to="/about">About</Anchor>
              </li>
              <li>
                <Anchor to="/blog">Blog</Anchor>
              </li>
              <li>
                <Anchor to="/lists">Lists</Anchor>
              </li>
              <li>
                <Box className="close-btn" onClick={handleNav} marginTop="xl">
                  <StyledHamburger open={navOpen}></StyledHamburger>
                </Box>
              </li>
            </ul>
          </StyledMobileNav>
          <StyledHamburger onClick={handleNav} open={navOpen}></StyledHamburger>
        </Flex>
      </GridContainer>
    </StyledHeader>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: `Samakintunde`,
};

export default Header;
