import { Link } from "gatsby";
import styled from "styled-components";

const Anchor = styled(Link)`
  color: ${props => props.theme.colors.shade500};
  transition: 0.3s ease-in;

  &:hover {
    color: ${props => props.theme.colors.shade600};
  }
`;

export default Anchor;
