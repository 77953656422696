import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledText = styled.p`
  color: ${props => props.theme.colors[props.color]};
  font-size: ${props => props.theme.typography[props.size]};
  font-weight: ${props => props.weight};
  line-height: 1.5;
  margin: 0;
`;

/**
 * Renders text
 * @param {{color: string, size: string, weight: number}} props
 */
const Text = props => {
  const { children } = props;

  return <StyledText {...props}>{children}</StyledText>;
};

Text.defaultProps = {
  color: "inherit",
  size: "regular",
  weight: 500,
};

Text.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  weight: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default Text;
