const SPACING = {
  // Uses Golden ratio
  none: "none",
  xxxs: "var(--spacing-xxxs)",
  xxs: "var(--spacing-xxs)",
  xs: "var(--spacing-xs)",
  sm: "var(--spacing-sm)",
  md: "var(--spacing-md)",
  lg: "var(--spacing-lg)",
  xl: "var(--spacing-xl)",
  xxl: "var(--spacing-xxl)",
  xxxl: "var(--spacing-xxxl)",
};

export default SPACING;
