/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO(props) {
  const {
    description,
    lang,
    meta,
    title,
    postData,
    frontmatter = {},
    postImage,
  } = props;

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const postMeta =
    frontmatter || postData.childMarkdownRemark.frontmatter || {};
  const metaDescription = description || site.siteMetadata.description;
  const url = postMeta.slug
    ? `${site.siteMetadata.siteUrl}/${postMeta.slug}/`
    : site.siteMetadata.siteUrl;
  // const datePublished = postMeta.datePublished || {};
  const isBlogPost = postMeta.datePublished ? true : false;
  const image = postImage
    ? `${site.siteMetadata.siteUrl}/${postImage}`
    : `${site.siteMetadata.siteUrl}/images/og-image.png`;

  return (
    <>
      <Helmet htmlAttributes={{ lang }}>
        {/* General Tags */}
        <title>
          {title} | ${site.siteMetadata.title}
        </title>
        <link rel="canonical" href={url} />
        <meta name="image" content={image} />

        {/* Open Graph tags */}
        <meta property="og:title" content={title} />
        <meta property="og:type" content={isBlogPost ? "article" : "website"} />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={image} />
        <meta property="og:description" content={metaDescription} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={site.siteMetadata.twitter} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={image} />
      </Helmet>
    </>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
