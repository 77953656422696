import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledFlex = styled.div`
  display: flex;
  flex-wrap: ${props => props.wrap && "wrap"};
  flex-direction: ${props => props.direction};
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};
  height: ${props => props.height};
  width: 100%;
`;

const Flex = props => {
  const { children } = props;

  return <StyledFlex {...props}>{children}</StyledFlex>;
};

Flex.defaultProps = {
  align: "flex-start",
  justify: "flex-start",
  height: "initial",
};

Flex.propTypes = {
  align: PropTypes.oneOf(["baseline", "flex-start", "center", "flex-end"]),
  justify: PropTypes.oneOf([
    "flex-start",
    "center",
    "flex-end",
    "space-between",
    "space-evenly",
    "space-around",
  ]),
  children: PropTypes.node.isRequired,
};

export default Flex;
