import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { generateResponsiveStyles } from "../../../theme/utils";

const StyledBox = styled.div`
  ${props => generateResponsiveStyles("width", props.width)};
  ${props => generateResponsiveStyles("height", props.height)};
  ${props => generateResponsiveStyles("padding", props.padding)};
  ${props => generateResponsiveStyles("padding-top", props.paddingTop)};
  ${props => generateResponsiveStyles("padding-right", props.paddingRight)};
  ${props => generateResponsiveStyles("padding-bottom", props.paddingBottom)};
  ${props => generateResponsiveStyles("padding-left", props.paddingLeft)};
  ${props => generateResponsiveStyles("margin", props.margin)};
  ${props => generateResponsiveStyles("margin-top", props.marginTop)};
  ${props => generateResponsiveStyles("margin-right", props.marginRight)};
  ${props => generateResponsiveStyles("margin-bottom", props.marginBottom)};
  ${props => generateResponsiveStyles("margin-left", props.marginLeft)};
  background-color: ${props => props.theme.colors[props.background]};
  ${props => generateResponsiveStyles("text-align", props.textAlign)};
  z-index: ${props => props.zIndex};
`;

const Box = props => {
  const { as, children } = props;

  return (
    <StyledBox as={as} {...props}>
      {children}
    </StyledBox>
  );
};

Box.defaultProps = {
  as: "div",
  background: "transparent",
};

Box.propTypes = {
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  background: PropTypes.string,
  textAlign: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Box;
